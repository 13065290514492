// メイン画面

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ListAltIcon from "@mui/icons-material/ListAlt";
// import LogoutIcon from "@mui/icons-material/Logout";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import Paper from "@mui/material/Paper";

import { platformAdapter } from "./lib/PlatformAdapter";
import { dbAdapter, CloudSyncState } from "./lib/DbAdapter";

import {
  //gapiLogoff,
  gapiSetOnLoginStatusChange,
  GOOGLE_DRIVE_ICON_URL,
} from "./lib/gapi";

// コンポーネント定義
const App: React.FC = () => {
  const navigate = useNavigate();

  const [profileIconURL, setProfileIconURL] = useState<string>(
    GOOGLE_DRIVE_ICON_URL
  );

  const [cloudState, setCloudState] = useState<CloudSyncState>("UNKNOWN");

  // 初期化
  useEffect(() => {
    gapiSetOnLoginStatusChange((status: boolean, _profileIconURL: string) => {
      setProfileIconURL(_profileIconURL);
    });

    dbAdapter.setOnChangeCloudSyncState((cloudSyncState: CloudSyncState) => {
      setCloudState(cloudSyncState);
    });
  }, []);

  const location = useLocation();

  return (
    <>
      {/* アプリバー */}
      <AppBar position="fixed">
        <Toolbar>
          {/* アプリタイトル */}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Talking Timer
          </Typography>

          {
            /* リスト表示 */
            location.pathname === "/main" && (
              <IconButton
                onClick={async () => {
                  navigate("/");
                }}
              >
                <ListAltIcon fontSize="large" />
              </IconButton>
            )
          }

          {/* ログイン */}
          <div style={{ position: "relative", display: "inline-block" }}>
            <Button
              onClick={async () => {
                platformAdapter.gapiLogin();
              }}
              sx={{ mr: -2 }}
            >
              <Paper
                elevation={5}
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={profileIconURL}
                  alt="Login"
                  width="30"
                  height="30"
                  style={{ borderRadius: "50%" }}
                />
              </Paper>
            </Button>

            <div style={{ position: "absolute", top: -5, right: -10 }}>
              {/* クラウド同期ステート */}
              {
                /* OFF */
                cloudState === "OFF" && <CloudOffIcon fontSize="small" />
              }
              {
                /* DOWNLOAD */
                cloudState === "DOWNLOAD" && (
                  <CloudDownloadIcon fontSize="small" color="secondary" />
                )
              }
              {
                /* UPLOAD */
                cloudState === "UPLOAD" && (
                  <CloudUploadIcon fontSize="small" color="secondary" />
                )
              }
              {
                /* DONE */
                cloudState === "DONE" && <CloudDoneIcon fontSize="small" />
              }
            </div>
          </div>

          {/* ログオフ 
          <IconButton
            onClick={async () => {
              gapiLogoff();
            }}
          >
            <LogoutIcon fontSize="large" />
          </IconButton>
          */}

          <Button
            onClick={async () => {
              /*
              window.adBreak({
                type: "next",
                name: "login",
                adBreakDone: (o: any) => {
                  alert("adBreakDone:" + o.breakStatus);
                },
              });
              */

              window.adBreak({
                type: "reward",
                name: "login",
                beforeReward: (showAdFn: any) => {
                  if (
                    window.confirm(
                      "広告を視聴してデータをGoogle Driveに同期しますか？"
                    )
                  ) {
                    showAdFn();
                  }
                },
                adDismissed: () => {
                  alert("adDismissed");
                },
                adViewed: () => {
                  alert("adViewed");
                },
                adBreakDone: (o: any) => {
                  alert("adBreakDone:" + o.breakStatus);
                },
              });
            }}
            color="secondary"
          >
            Ad test
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default App;
