import { v4 as uuidv4 } from "uuid";

import { Playlist } from "./Playlist";
import { talkingTimerMgr } from "./TalkingTimerMgr";
import { dbAdapter } from "./DbAdapter";
import { AsyncOperationManager } from "./AsyncOperationManager";

export class PlaylistMgr {
  private currentIdx: number;

  playlists: Playlist[];
  private currentTimerIdx: number;

  idbLoad: AsyncOperationManager<any>;
  driveLoad: AsyncOperationManager<any>;

  onTimerChange: () => void;

  public constructor() {
    this.playlists = [];
    this.currentIdx = -1;
    this.currentTimerIdx = 0;
    this.onTimerChange = () => {};

    this.idbLoad = new AsyncOperationManager();
    this.driveLoad = new AsyncOperationManager();

    this.load();
  }

  public setCurrent(index: number) {
    this.currentIdx = index;
  }

  public current() {
    if (this.currentIdx < this.playlists.length && this.currentIdx >= 0) {
      return this.playlists[this.currentIdx];
    } else {
      return null;
    }
  }

  private async load() {
    // iDBより全データロード
    this.playlists = await dbAdapter.loadLocal("timerPlayLists");

    // 自動ログイン & Driveからのロードと同期
    dbAdapter
      .loadAndSyncToDriveWithLogin("timerPlayLists", this.playlists)
      .then(() => {
        this.driveLoad.done();
      })
      .catch(() => {
        this.driveLoad.fail();
      });

    // 昇順でソート
    this.playlists.sort((a, b) => a.name.localeCompare(b.name));

    this.idbLoad.done();
    return this.playlists;
  }

  public async add(newPlaylist: Playlist) {
    // timersに反映
    this.playlists = [...this.playlists, newPlaylist];

    // 昇順でソート
    this.playlists.sort((a, b) => a.name.localeCompare(b.name));

    dbAdapter.add("timerPlayLists", newPlaylist, this.playlists);

    return this.playlists;
  }

  public async delete(index: number) {
    const deletedId = this.playlists[index].id;
    this.playlists = this.playlists.filter((_, i) => i !== index);

    // iDBに反映
    await dbAdapter.delete("timerPlayLists", deletedId, this.playlists);

    return this.playlists;
  }

  public async copy(index: number) {
    let copiedPlaylist = { ...this.playlists[index] };
    copiedPlaylist.id = uuidv4();
    copiedPlaylist.name += " -copy";
    await this.add(copiedPlaylist);

    return this.playlists;
  }

  public async addTimer(index: number, timerID: string) {
    this.playlists[index].talkingTimerIDs = [
      ...this.playlists[index].talkingTimerIDs,
      timerID,
    ];

    dbAdapter.update("timerPlayLists", this.playlists[index], this.playlists);

    return this.playlists;
  }

  public async deleteTimer(index_: number, index: number) {
    this.playlists[index_] = {
      ...this.playlists[index_],
      talkingTimerIDs: this.playlists[index_].talkingTimerIDs.filter(
        (_, i) => i !== index
      ),
    } as Playlist;

    dbAdapter.update("timerPlayLists", this.playlists[index_], this.playlists);

    // 指定タイマーを削除したプレイリストの一覧
    this.playlists = this.playlists.map((playlist) => {
      if (playlist === this.playlists[index_]) {
        return this.playlists[index_];
      } else {
        return playlist;
      }
    });

    return this.playlists;
  }

  // リネーム
  // TODO: 他の更新系をまとめてupdateとかにする？
  public async rename(index: number, newName: string) {
    this.playlists[index].name = newName;

    dbAdapter.update("timerPlayLists", this.playlists[index], this.playlists);

    return this.playlists;
  }

  // ボリューム変化
  // TODO: 他の更新系をまとめてupdateとかにする？これは使いどころからしてindexが引数に不要だが...
  public async setVolume(volume: number) {
    this.playlists[this.currentIdx].volume = volume;

    dbAdapter.update(
      "timerPlayLists",
      this.playlists[this.currentIdx],
      this.playlists
    );

    return this.playlists;
  }

  // タイマーインデックスを取得
  public getCurrentTimerIdx() {
    return this.currentTimerIdx;
  }

  // プレイリスト内のタイマーインデックスから全体のタイマーインデックスを取得
  public getTimerIdx() {
    if (this.current()) {
      // 対応するtimerIDを取得
      const timerID = this.current()!.talkingTimerIDs[this.currentTimerIdx];

      // そのtimeIDを持つタイマーインデックスを取得 : TOTO そもそも最初からIDにした方が...?
      return talkingTimerMgr.timers.findIndex((timer) => timer.id === timerID);
    }
  }

  // タイマーインデックスをセット
  public setCurrentTimerIdx(index: number) {
    if (this.current()) {
      this.currentTimerIdx = index;

      // そのtimeIDを持つタイマーインデックスを取得
      const timerIndex = this.getTimerIdx();

      if (timerIndex !== undefined && timerIndex >= 0) {
        // タイマー読み込み
        talkingTimerMgr.setCurrent(timerIndex);
        this.onTimerChange();

        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 1つ進める
  private forward() {
    if (this.current() !== null) {
      // 現在のプレイリスト内に次のタイマーがある場合
      if (this.currentTimerIdx < this.current()!.talkingTimerIDs.length - 1) {
        return this.setCurrentTimerIdx(this.currentTimerIdx + 1);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 1つ進めてタイマーもリフレッシュ
  public forwardEx() {
    // 再生中ならいったん停止
    let wasPlaying = talkingTimerMgr.isPlaying;
    talkingTimerMgr.pause();

    // 進む
    this.forward();

    // もともと再生中だったら再開
    if (wasPlaying) {
      talkingTimerMgr.play();
    }
  }

  // 1つ戻る
  private backward() {
    if (this.current() !== null) {
      // 現在のプレイリスト内に前のタイマーがある場合
      if (this.currentTimerIdx > 0) {
        return this.setCurrentTimerIdx(this.currentTimerIdx - 1);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 2番目以降かつ1秒以内なら戻る、そうでなければリセット
  public backwardOrReset() {
    let isBackward = false;
    if (this.current() !== null) {
      const timerIndex = this.getTimerIdx();

      if (timerIndex !== undefined && timerIndex >= 0) {
        if (
          this.currentTimerIdx !== 0 &&
          talkingTimerMgr.timers[timerIndex].totalTimeSec -
            talkingTimerMgr.currentRestTimeSec <
            1
        ) {
          isBackward = true;
        }
      }
    }

    // 再生中ならいったん停止
    let wasPlaying = talkingTimerMgr.isPlaying;
    talkingTimerMgr.pause();

    // 戻るかリセット
    if (isBackward) {
      this.backward();
    } else {
      talkingTimerMgr.handleReset();
    }

    // もともと再生中だったら再開
    if (wasPlaying) {
      talkingTimerMgr.play();
    }
  }

  // タイマー終了時に次をチェック
  public checkNext() {
    // 残り時間を戻す
    talkingTimerMgr.handleReset();

    // 現在のプレイリストが存在するか
    if (this.current() !== null) {
      // 現在のプレイリスト内に次のタイマーがある場合は進む
      if (this.forward()) {
        // 次がある場合はタイマーをクリアさせない（バックグラウンド時に止まるため）

        // タイマー停止/再生
        talkingTimerMgr.pause(false);
        talkingTimerMgr.play();
      }
      // 次がない場合はプレイリスト終了処理
      else {
        // 現在のプレイリストの最初のタイマーに戻しておく
        this.setCurrentTimerIdx(0);

        // 停止
        talkingTimerMgr.pause();
      }
    }
    // 現在のプレイリストが存在しなければ、単純停止
    else {
      talkingTimerMgr.pause();
    }
  }

  public setOnTimerChange(callback: () => void) {
    // onTimerChangeを設定
    this.onTimerChange = callback;
  }
}

export const playlistMgr = new PlaylistMgr();
