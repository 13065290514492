import { v4 as uuidv4 } from "uuid";

import { TalkingTimer } from "./TalkingTimer";
import { playlistMgr } from "./PlaylistMgr";
import { platformAdapter } from "./PlatformAdapter";
import { dbAdapter } from "./DbAdapter";
import { AsyncOperationManager } from "./AsyncOperationManager";

import queryString from "query-string";

// 秒の値→分の文字列
const _getMinutesStr = (timeSec: number) => {
  let minutes = Math.floor(timeSec / 60);
  let minutesStr = "";
  minutesStr = minutes.toString();
  return minutesStr;
};

// 秒の値→秒の文字列
const _getSecondsStr = (timeSec: number) => {
  let seconds = timeSec % 60;
  let secondsStr = "";
  secondsStr = seconds.toString();
  return secondsStr;
};

// スピーチのパース
const _parseSpeech = (str: string, timeSec: number, totalTimeSec: number) => {
  // 暫定：とりあえず秒入力に対して分表現を追加

  // 経過時間
  str = str.replace("$em", _getMinutesStr(totalTimeSec - timeSec));
  str = str.replace("$es", _getSecondsStr(totalTimeSec - timeSec));

  // 残り時間
  str = str.replace("$m", _getMinutesStr(timeSec));
  str = str.replace("$s", _getSecondsStr(timeSec));

  return str;
};

// パースとスピーチ
export const parseAndSpeech = (
  str: string,
  timeSec: number = 0,
  totalTimeSec: number = 0
) => {
  let result = _parseSpeech(str, timeSec, totalTimeSec);
  console.log("発声:" + result);
  platformAdapter.speech(result);
};

export class TalkingTimerMgr {
  private currentIdx: number;
  timers: TalkingTimer[];

  updateCurrentRestTimeSec: (timeSec: number) => void;
  currentRestTimeSec: number;

  updateIsPlaying: (flag: boolean) => void;
  isPlaying: boolean;

  private originalVolume: number;

  idbLoad: AsyncOperationManager<any>;

  public constructor() {
    this.timers = [];
    this.currentIdx = -1;

    this.updateCurrentRestTimeSec = () => {};
    this.currentRestTimeSec = 0;

    this.updateIsPlaying = () => {};
    this.isPlaying = false;

    this.idbLoad = new AsyncOperationManager();

    this.load();

    // 音量取得コールバック設定
    platformAdapter.setOnGetVolume(this.onGetVolume1, 1);
    this.originalVolume = 0;
  }

  public onGetVolume1(volume: number) {
    // note: ここでthisを使うとなぜか呼び出し元のPlatformAdapterになる
    talkingTimerMgr.originalVolume = volume;
  }

  public setCurrent(index: number) {
    this.currentIdx = index;
  }

  public current() {
    if (this.currentIdx < this.timers.length) {
      return this.timers[this.currentIdx];
    } else {
      return null;
    }
  }

  public setUpdateIsPlaying(callback: (flag: boolean) => void) {
    this.updateIsPlaying = callback;
  }

  public setUpdateCurrentRestTimeSec(callback: (timeSec: number) => void) {
    this.updateCurrentRestTimeSec = callback;
  }

  private async load() {
    // iDBより全データロード
    this.timers = await dbAdapter.loadLocal("talkingTimers");

    // 自動ログイン & Driveからのロードと同期
    dbAdapter
      .loadAndSyncToDriveWithLogin("talkingTimers", this.timers)
      .then(() => {
        // プレイリスト側のDriveロードと同期が完了していて、実際にロードが発生していたらまとめてリロード
        playlistMgr.driveLoad.wait().then(() => {
          if (dbAdapter.isDriveDownloaded) {
            talkingTimerMgr.pause();
            // TODO: /main パスがあると実機で動かないので、現時点では弾いている。
            window.location.href =
              window.location.origin + window.location.search;
          }
        });
      });

    // 昇順でソート
    this.timers.sort((a, b) => a.name.localeCompare(b.name));

    // クエリ文字列から自動再生
    // URLのクエリ文字列を取得
    const query = window.location.search;

    // クエリ文字列を解析
    const parsed = queryString.parse(query);

    // 解析結果から値を取得
    const type = parsed.type;
    const id = parsed.id;

    if (type === "playlist") {
      // 現在のプレイリストを設定
      await playlistMgr.idbLoad.wait();
      const playlistIdx = playlistMgr.playlists.findIndex(
        (playlist) => playlist.id === id
      );
      playlistMgr.setCurrent(playlistIdx as number);

      // 現在のプレイリストの1つ目のタイマーを現在のタイマーとして設定
      const timerID =
        playlistMgr.playlists[playlistIdx as number].talkingTimerIDs[0];
      const timerIdx = talkingTimerMgr.timers.findIndex(
        (timer) => timer.id === timerID
      );

      if (timerIdx < 0) {
        console.log("no timer");
        return;
      }
      this.setCurrent(timerIdx);
    } else if (type === "timer") {
      const timerIdx = talkingTimerMgr.timers.findIndex(
        (timer) => timer.id === id
      );

      if (timerIdx < 0) {
        console.log("no timer");
        return;
      }
      this.setCurrent(timerIdx);
    }

    this.idbLoad.done();
    return this.timers;
  }

  public async add(newTimer: TalkingTimer) {
    // id生成
    newTimer.id = uuidv4();

    // timersに反映
    this.timers = [...this.timers, newTimer];

    // 昇順でソート
    this.timers.sort((a, b) => a.name.localeCompare(b.name));

    dbAdapter.add("talkingTimers", newTimer, this.timers);

    return this.timers;
  }

  public async update(newTimer: TalkingTimer) {
    // timersに反映
    // 対応するtimeIDを持つタイマーインデックスを取得
    const index = talkingTimerMgr.timers.findIndex(
      (timer) => timer.id === newTimer.id
    );

    this.timers[index] = newTimer;

    dbAdapter.update("talkingTimers", newTimer, this.timers);

    return this.timers;
  }

  public async addOrUpdate(newTimer: TalkingTimer) {
    // 現在のインデックスが配列範囲内であれば更新
    if (this.currentIdx < this.timers.length) {
      newTimer.id = this.timers[this.currentIdx].id;
      this.update(newTimer);
    }
    // そうでなければ追加
    else {
      this.add(newTimer);
    }
  }

  public async addIndex() {
    if (this.timers.length > 0) {
      this.setCurrent(this.timers.length);
    } else {
      this.setCurrent(0);
    }
  }

  public async delete(index: number) {
    const deletedId = this.timers[index].id;
    this.timers = this.timers.filter((_, i) => i !== index);

    dbAdapter.delete("talkingTimers", deletedId, this.timers);

    return this.timers;
  }

  public async copy(index: number) {
    let copiedTimer = { ...this.timers[index] };
    copiedTimer.id = uuidv4();
    copiedTimer.name += " -copy";
    await this.add(copiedTimer);

    return this.timers;
  }

  // タイマーハンドラ
  public onOneSecInterval() {
    // 再生中でないときは無視（プレイリストの間）
    if (!this.isPlaying) return;

    // 時間を1秒減らす
    this.currentRestTimeSec -= 1;
    this.updateCurrentRestTimeSec(this.currentRestTimeSec);

    // インターバル処理
    this.handleInterval();
  }

  // インターバル処理
  public handleInterval() {
    // 開始メッセージフラグ：定期メッセージとの排他に使用
    let isStartMessage = false;

    // 開始メッセージ：残り時間がトータル時間と同じ
    if (this.currentRestTimeSec === this.timers[this.currentIdx].totalTimeSec) {
      parseAndSpeech(
        this.timers[this.currentIdx].startMessage,
        this.currentRestTimeSec,
        this.timers[this.currentIdx].totalTimeSec
      );

      isStartMessage = true;
    }

    // カスタムメッセージ発声評価
    // TODO: 同じ時間のメッセージは1つ目だけ...エラーとかにした方がいいか？
    if (this.current()) {
      for (let message of this.current()!.customMessages) {
        if (message.timeSec === this.currentRestTimeSec) {
          parseAndSpeech(
            message.message,
            this.currentRestTimeSec,
            this.current()!.totalTimeSec
          );
        }
      }
    }

    // 終了メッセージ：残り時間が0秒
    if (this.currentRestTimeSec === 0) {
      // 発話
      parseAndSpeech(
        this.timers[this.currentIdx].endMessage,
        this.currentRestTimeSec,
        this.timers[this.currentIdx].totalTimeSec
      );

      // 次をチェック
      playlistMgr.checkNext();
    }

    // 定期メッセージ：開始と終了以外で、残り時間が定期インターバルの倍数
    else if (
      !isStartMessage &&
      this.currentRestTimeSec % this.timers[this.currentIdx].periodicTimeSec ===
        0
    ) {
      parseAndSpeech(
        this.timers[this.currentIdx].periodicMessage,
        this.currentRestTimeSec,
        this.timers[this.currentIdx].totalTimeSec
      );
    }
  }

  // タイマー再生（再生中は何もしない）
  public async play() {
    // 音声初期化待機
    await platformAdapter.voiceLoad.wait();

    if (!this.isPlaying) {
      platformAdapter.requestGetVolume(1);
      // プレイリスト音量が有効であれば設定
      if (playlistMgr.current() !== null) {
        if (playlistMgr.current()!.volume >= 0) {
          platformAdapter.setVolume(playlistMgr.current()!.volume);
        }
      }
      // タイマー音量が有効であれば設定
      else {
        if (talkingTimerMgr.current() !== null) {
          if (talkingTimerMgr.current()!.volume >= 0) {
            platformAdapter.setVolume(talkingTimerMgr.current()!.volume);
          }
        }
      }

      // 再生状態を反転
      this.updateIsPlaying(!this.isPlaying);
      this.isPlaying = !this.isPlaying;

      // 初期フレームタイマー
      this.handleInterval();

      // setInterval開始
      if (this.currentRestTimeSec > 0) {
        platformAdapter.setOneSecInterval(() => this.onOneSecInterval());
      }
    }
  }

  // タイマー一時停止（停止中は何もしない）
  public pause(isClear: boolean = true) {
    if (this.isPlaying) {
      // 再生状態を反転
      this.updateIsPlaying(!this.isPlaying);
      this.isPlaying = !this.isPlaying;

      // タイマーをクリア
      if (isClear) {
        platformAdapter.clearOneSecInterval();

        // 音量を再生開始前の値に復帰
        // TODO: 発声完了を待機する必要があるのでいったん無効化: https://www.phind.com/search?cache=pxbgdxjx4vp1kcrf9rifdm0u
        // platformAdapter.setVolume(this.originalVolume);
      }
    }
  }

  // タイマー開始/一時停止トグル
  public TogglePlayOrPause() {
    // 停止→再生
    if (!this.isPlaying) {
      this.play();
    }
    // 一時停止
    else {
      this.pause();
    }
  }

  // リセット
  public handleReset() {
    this.currentRestTimeSec = this.timers[this.currentIdx].totalTimeSec;
    this.updateCurrentRestTimeSec(this.currentRestTimeSec);
  }
}

export const talkingTimerMgr = new TalkingTimerMgr();
