// TextFiledをラップし、タイマーメッセージ対応するコンポーネント
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// プロパティ
interface MessageTextFieldProps {
  label: string; // ラベル
  value: string; // メッセージ
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>; // メッセージの変更ハンドラ
  onClick?: any; // クリックハンドラ
  showPreview?: boolean; // 音声プレビューの有無
}

// コンポーネント定義
const MessageTextField: React.FC<MessageTextFieldProps> = ({
  label,
  value,
  onChange,
  onClick = null,
  showPreview = true,
}) => {
  // 表示更新用の内部バリュー
  const [internalValue, setInternalValue] = useState<string>("");

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <TextField
      multiline
      size="small"
      label={label}
      onChange={(event) => {
        setInternalValue(event.target.value);
      }}
      onBlur={(event) => {
        if (event.target.value !== value) {
          onChange(event);
        }
      }}
      type="text"
      value={internalValue}
      fullWidth
      InputProps={{
        endAdornment: showPreview && (
          <InputAdornment position="end">
            <IconButton onClick={onClick} edge="end">
              <PlayCircleIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
          event.preventDefault();
          const target = event.target as HTMLElement; // Type assertion
          target.blur();
        }
      }}
    />
  );
};

export default MessageTextField;
