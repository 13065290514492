// メイン画面

import React, { useState, useEffect } from "react";
import { useConfirm } from "material-ui-confirm";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid"; // Grid version 1
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InputIcon from "@mui/icons-material/Input";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DeleteIcon from "@mui/icons-material/Delete";

import { v4 as uuidv4 } from "uuid";

import TimeTextField from "./TimeTextField";
import MessageTextField from "./MessageTextField";
import PasteDialog from "./PasteDialog";
import InsertDialog from "./InsertDialog";
import { parseAndSpeech } from "./lib/TalkingTimerMgr";

import { talkingTimerMgr } from "./lib/TalkingTimerMgr";
import { CustomMessage, TalkingTimer } from "./lib/TalkingTimer";
import { playlistMgr } from "./lib/PlaylistMgr";
import { platformAdapter } from "./lib/PlatformAdapter";
import { Adsense } from "@ctrl/react-adsense";
import "./index.css";

// コンポーネント定義
export interface SequencerProps {
  currentTimerPlayListName: string;
  currentTimerPlayListVol: number;
  currentTimerVol: number; // TODO: talkingTimerに含まれていれば不要？
  talkingTimer: TalkingTimer;
  setTalkingTimer: (timer: TalkingTimer) => void;
}

// コンポーネント定義
const Sequencer: React.FC<SequencerProps> = ({
  currentTimerPlayListName,
  currentTimerPlayListVol,
  currentTimerVol,
  talkingTimer,
  setTalkingTimer,
}) => {
  // 確認ダイアログ
  const confirm = useConfirm();

  // 貼り付けダイアログ
  const [initPasteDialogTimeSec, setInitPasteDialogTimeSec] =
    useState<number>(0); // 貼り付け先の初期値
  const [copyLength, setCopyLength] = useState<number>(0); // 反復コピーする長さ
  const [openPasteDialog, setOpenPasteDialog] = useState<boolean>(false); // ダイアログの開閉

  // 挿入ダイアログ
  const [openInsertDialog, setOpenInsertDialog] = useState<boolean>(false); // ダイアログの開閉

  // 初期化
  useEffect(() => {}, []);

  // idbへの保存,　ステート更新
  const saveData = async (updatedTalkingTimer: TalkingTimer) => {
    try {
      // 現在のインデックスが配列範囲内であれば更新
      talkingTimerMgr.addOrUpdate(updatedTalkingTimer);
    } catch (e) {
      console.log("saveData catch:" + e);
    }

    setTalkingTimer(updatedTalkingTimer);

    // TODO: talkingTimerMgr.setCurrent をidで指定
  };

  // UI更新による現在値のメモリ/idb保存（テキスト）
  interface HandleChangeText {
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      property: keyof TalkingTimer
    ): void;
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      property: "customMessages",
      custom_index: number,
      custom_property: keyof CustomMessage
    ): void;
  }
  const handleChangeText: HandleChangeText = async (
    event,
    property,
    custom_index?,
    custom_property?
  ) => {
    let updatedTalkingTimer: TalkingTimer;
    // カスタムメッセージ配列propertyの扱い
    if (property === "customMessages" && custom_index !== undefined) {
      const newCustomMessages = [...talkingTimer.customMessages];
      const updatedCustomMessage = {
        ...talkingTimer.customMessages[custom_index as number],
        [custom_property as keyof CustomMessage]: event.target.value,
      };
      newCustomMessages[custom_index as number] = updatedCustomMessage;
      updatedTalkingTimer = {
        ...talkingTimer,
        customMessages: newCustomMessages,
      };
    }
    // それ以外の単品propertyの扱い
    else {
      updatedTalkingTimer = {
        ...talkingTimer,
        [property]: event.target.value,
      };
    }

    await saveData(updatedTalkingTimer);
  };

  // カスタムメッセージの更新
  const updateCustomMesssages = async (newCustomMessages: CustomMessage[]) => {
    // ソート
    newCustomMessages.sort((a, b) => {
      return b.timeSec - a.timeSec;
    });

    // トータルタイムをオーバーフローした時の更新
    const overflowTimeSec = newCustomMessages.slice(-1)[0].timeSec;
    let newTotalTimeSec = talkingTimer.totalTimeSec;
    if (overflowTimeSec < 0) {
      for (let msg of newCustomMessages) {
        msg.timeSec -= overflowTimeSec;
      }
      newTotalTimeSec -= overflowTimeSec;

      // 残り時間も更新
      talkingTimerMgr.currentRestTimeSec -= overflowTimeSec;
    }

    // ステート更新
    const updatedTalkingTimer = {
      ...talkingTimer,
      totalTimeSec: newTotalTimeSec,
      customMessages: newCustomMessages,
      forceUpdate: uuidv4(),
    };

    await saveData(updatedTalkingTimer);

    return overflowTimeSec < 0 ? overflowTimeSec : 0;
  };

  // UI更新による現在値のメモリ/idb保存(number)
  interface HandleChangeNumber {
    (value: number, property: keyof TalkingTimer): void;
    (
      value: number,
      property: "customMessages",
      custom_index: number,
      custom_property: keyof CustomMessage
    ): void;
  }
  const handleChangeNumber: HandleChangeNumber = async (
    value,
    property,
    custom_index?,
    custom_property?
  ) => {
    // カスタムメッセージ配列propertyの扱い
    if (property === "customMessages" && custom_index !== undefined) {
      const newCustomMessages = [...talkingTimer.customMessages];
      const updatedCustomMessage = {
        ...talkingTimer.customMessages[custom_index as number],
        [custom_property as keyof CustomMessage]: value,
      };

      // 新しいカスタムメッセージを配列上で更新
      newCustomMessages[custom_index as number] = updatedCustomMessage;

      // カスタムメッセージの更新
      updateCustomMesssages(newCustomMessages);
    }
    // それ以外の単品propertyの扱い
    else {
      let updatedTalkingTimer = {
        ...talkingTimer,
        [property]: value,
      };

      // totalTimeSecの場合
      if (property === "totalTimeSec") {
        // リセット
        talkingTimerMgr.currentRestTimeSec = updatedTalkingTimer.totalTimeSec;

        // countUp時は経過時間が不変になるように残り時間を更新
        if (talkingTimer.isCountDown === false) {
          const diffTotalTimeSec = value - talkingTimer.totalTimeSec;
          for (let msg of updatedTalkingTimer.customMessages) {
            msg.timeSec += diffTotalTimeSec;
          }
        }
      }

      await saveData(updatedTalkingTimer);
    }
  };

  // カウントダウン/アップ
  const handleToggleCountDown = async (
    event: React.MouseEvent<HTMLElement>,
    newIsCountDown: boolean
  ) => {
    if (newIsCountDown !== null) {
      const updatedTalkingTimer = {
        ...talkingTimer,
        isCountDown: newIsCountDown,
      };

      await saveData(updatedTalkingTimer);
    }
  };

  // カスタムメッセージの選択チェックボックス用コントロール
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    customMessage: CustomMessage
  ) => {
    if (event.target.checked) {
      setCheckedIds([...checkedIds, customMessage.id]);
    } else {
      setCheckedIds(checkedIds.filter((id) => id !== customMessage.id));
    }
  };

  return (
    <>
      {/* シーケンサー */}
      <div className="center-ad">
        <Adsense
          client="ca-pub-5097506678290473a"
          slot="8353008403"
          style={{ width: 320, height: 50 }}
          format=""
        />
      </div>
      <Grid container spacing={2}>
        {playlistMgr.current() && (
          <>
            {/* プレイリスト名 */}
            <Grid item xs={10}>
              <TextField
                variant="standard"
                label="プレイリスト名"
                value={currentTimerPlayListName}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            {/* プレイリスト ボリューム */}
            <Grid item xs={2}>
              <TextField
                variant="standard"
                label="Vol."
                value={currentTimerPlayListVol}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                onClick={() => {
                  // 現在の音量を取得依頼 (コールバックはMain側)
                  platformAdapter.requestGetVolume(2);
                }}
              />
            </Grid>
          </>
        )}

        {/* タイマー名 */}
        <Grid item xs={10}>
          <TextField
            variant="standard"
            label="タイマー名"
            value={talkingTimer.name}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>

        {/* タイマー ボリューム (プレイリスト時は無効化）*/}
        <Grid item xs={2}>
          <TextField
            variant="standard"
            label="Vol."
            value={currentTimerVol}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            onClick={() => {
              // 現在の音量を取得依頼 (コールバックはMain側)
              platformAdapter.requestGetVolume(2);
            }}
            disabled={currentTimerPlayListName !== ""} // TODO: ちょっとハック、無題のプレイリストがあったら...
          />
        </Grid>

        {/* トータル時間 */}
        <Grid item xs={7}>
          <TimeTextField
            label="トータル時間"
            timeSec={talkingTimer.totalTimeSec}
            onChange={(value) => handleChangeNumber(value, "totalTimeSec")}
          />
        </Grid>

        {/* 開始メッセージ */}
        <Grid item xs={12}>
          <MessageTextField
            label="開始メッセージ"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleChangeText(event, "startMessage")}
            onClick={() => {
              parseAndSpeech(
                talkingTimer.startMessage,
                talkingTimer.totalTimeSec,
                talkingTimer.totalTimeSec
              );
            }}
            value={talkingTimer.startMessage}
          />
        </Grid>

        {/* 終了メッセージ */}
        <Grid item xs={12}>
          <MessageTextField
            label="終了メッセージ"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleChangeText(event, "endMessage")}
            onClick={() => {
              parseAndSpeech(
                talkingTimer.endMessage,
                0,
                talkingTimer.totalTimeSec
              );
            }}
            value={talkingTimer.endMessage}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* 定期タイマー間隔 */}
        <Grid item xs={7}>
          <TimeTextField
            label="定期タイマー間隔"
            timeSec={talkingTimer.periodicTimeSec}
            onChange={(value) => handleChangeNumber(value, "periodicTimeSec")}
          />
        </Grid>

        {/* 定期メッセージ */}
        <Grid item xs={12}>
          <MessageTextField
            label="定期メッセージ"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => handleChangeText(event, "periodicMessage")}
            onClick={() => {
              parseAndSpeech(talkingTimer.periodicMessage);
            }}
            value={talkingTimer.periodicMessage}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* カスタムメッセージ タイトル */}
        {talkingTimer.customMessages &&
          talkingTimer.customMessages.length > 0 && (
            <Grid item xs={12}>
              <Typography>カスタムメッセージ</Typography>
            </Grid>
          )}

        {/* カウントダウン・アップ トグル */}
        {talkingTimer.customMessages &&
          talkingTimer.customMessages.length > 0 && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                value={talkingTimer.isCountDown}
                exclusive
                onChange={handleToggleCountDown}
                aria-label="Count"
              >
                <ToggleButton value={true}>Count Down</ToggleButton>
                <ToggleButton value={false}>Count Up</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}

        {/* カスタム */}
        {talkingTimer.customMessages &&
          talkingTimer.customMessages.map((element, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
              {/* カスタムメッセージ時間 */}
              <Grid item xs={6}>
                <TimeTextField
                  label={""}
                  timeSec={element.timeSec}
                  onChange={(value) =>
                    handleChangeNumber(
                      value,
                      "customMessages",
                      index,
                      "timeSec"
                    )
                  }
                  countDownOrUp={
                    talkingTimer.isCountDown ? "countDown" : "countUp"
                  }
                  totalTimeSec={talkingTimer.totalTimeSec}
                  forceUpdate={talkingTimer.forceUpdate}
                />
              </Grid>
              {/* スペース */}
              <Grid item xs={2}></Grid>
              {/* カスタムメッセージ削除ボタン */}
              <Grid item xs={2}>
                <IconButton
                  onClick={async () => {
                    confirm({
                      title: "カスタムメッセージ" + index + "を削除しますか?",
                      description: "この操作は元に戻せません。",
                      confirmationButtonProps: { color: "secondary" },
                    })
                      .then(async () => {
                        const newCustomMessages =
                          talkingTimer.customMessages.filter(
                            (_, i) => i !== index
                          );
                        const updatedTalkingTimer = {
                          ...talkingTimer,
                          customMessages: newCustomMessages,
                        };

                        await saveData(updatedTalkingTimer);
                      })
                      .catch(() => {
                        /* ... */
                      });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              {/* 選択チェックボックス */}
              <Grid item xs={2}>
                <Checkbox
                  checked={checkedIds.includes(element.id)}
                  onChange={(event) => handleCheckboxChange(event, element)}
                />
              </Grid>
              {/* カスタムメッセージ */}
              <Grid item xs={12}>
                <MessageTextField
                  label={"メッセージ" + index}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) =>
                    handleChangeText(event, "customMessages", index, "message")
                  }
                  onClick={() => {
                    parseAndSpeech(
                      element.message,
                      element.timeSec,
                      talkingTimer.totalTimeSec
                    );
                  }}
                  value={element.message}
                />
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
      {/* カスタムメッセージ追加ボタン */}
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        onClick={() => {
          let lastTimeSec = 0;

          // 単一選択中であればその時間を初期値とする
          if (checkedIds.length === 1) {
            const checked = talkingTimer.customMessages
              .filter((msg) => checkedIds.includes(msg.id))
              .map((obj) => ({ ...obj }));

            lastTimeSec = checked[0].timeSec;
          }
          // 現状の最新の時間を初期値とする
          else {
            let lastIdx = talkingTimer.customMessages.length - 1;
            if (lastIdx >= 0) {
              lastTimeSec = talkingTimer.customMessages[lastIdx].timeSec;
            }
          }

          const newCustomMessages = [
            ...talkingTimer.customMessages,
            {
              message: "",
              timeSec: lastTimeSec,
              id: uuidv4(),
            },
          ];

          updateCustomMesssages(newCustomMessages);
        }}
      >
        <PlaylistAddIcon />
      </Fab>
      {
        /*カスタムメッセージ複数選択中にコピーFAB表示*/
        checkedIds.length > 0 && (
          <Fab
            color="primary"
            aria-label="copy"
            sx={{ position: "fixed", bottom: 16, right: 96 }}
            onClick={() => {
              // 貼り付けダイアログ表示
              setInitPasteDialogTimeSec(
                (() => {
                  if (
                    talkingTimer &&
                    talkingTimer.customMessages &&
                    talkingTimer.customMessages.length > 0
                  ) {
                    return talkingTimer.customMessages[
                      talkingTimer.customMessages.length - 1
                    ].timeSec;
                  } else {
                    return 0;
                  }
                })()
              );
              setOpenPasteDialog(true);
              setCopyLength(0);
            }}
          >
            <ContentCopyIcon />
          </Fab>
        )
      }
      {/*貼り付けダイアログ*/}
      <PasteDialog
        open={openPasteDialog}
        onClose={() => {
          setOpenPasteDialog(false);
        }}
        onChange={async (value) => {
          // 貼り付け対象として選択されているカスタムメッセージの部分配列を取得
          const copied = talkingTimer.customMessages
            .filter((msg) => checkedIds.includes(msg.id))
            .map((obj) => ({ ...obj }));

          // 次の貼り付け先初期値計算用値保持
          const originalFirstCopyTime = copied[0].timeSec;

          // 貼り付け先の時間を基準として時間をオフセット
          for (let i = copied.length - 1; i >= 0; i--) {
            copied[i].timeSec = copied[i].timeSec - copied[0].timeSec + value;
            copied[i].id = uuidv4(); // idもついでに変更
          }

          // 追加
          let newCustomMessages = talkingTimer.customMessages.concat(copied);

          // カスタムメッセージの更新
          let overflowTimeSec = await updateCustomMesssages(newCustomMessages);

          // 次の貼り付け先初期値
          let newCopyLength = 0;
          if (copyLength === 0) {
            newCopyLength = value - originalFirstCopyTime;
            setCopyLength(newCopyLength);
          } else {
            newCopyLength = copyLength;
          }
          setInitPasteDialogTimeSec(value + newCopyLength - overflowTimeSec);
        }}
        timeSec={initPasteDialogTimeSec}
        countDownOrUp={talkingTimer.isCountDown ? "countDown" : "countUp"}
        totalTimeSec={talkingTimer.totalTimeSec}
      ></PasteDialog>
      {
        /*カスタムメッセージ1つ選択中にインサートFAB表示*/
        checkedIds.length === 1 && (
          <Fab
            color="primary"
            aria-label="copy"
            sx={{ position: "fixed", bottom: 16, right: 176 }}
            onClick={() => {
              setOpenInsertDialog(true);
            }}
          >
            <InputIcon />
          </Fab>
        )
      }
      {/*挿入ダイアログ*/}
      <InsertDialog
        open={openInsertDialog}
        onClose={() => {
          setOpenInsertDialog(false);
        }}
        onChange={async (value) => {
          // 追加
          let newCustomMessages = talkingTimer.customMessages.map((obj) => ({
            ...obj,
          }));

          // 現在選択しているカスタムメッセージ以降は時間挿入
          let isAfter = false;
          for (let msg of newCustomMessages) {
            if (msg.id === checkedIds[0] || isAfter) {
              msg.timeSec -= value;
              isAfter = true;
            }
          }

          // カスタムメッセージの更新
          await updateCustomMesssages(newCustomMessages);
        }}
        timeSec={0}
      ></InsertDialog>
    </>
  );
};

export default Sequencer;
