// タイマーコントローラ
// タイマー制御用のUI

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid"; // Grid version 1
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import { format } from "date-fns";

import { talkingTimerMgr } from "./lib/TalkingTimerMgr";
import { Playlist } from "./lib/Playlist";
import { playlistMgr } from "./lib/PlaylistMgr";
import { platformAdapter } from "./lib/PlatformAdapter";

// コンポーネント定義
export interface TimerControllerProps {
  totalTimeSec: number;
  isCountDown: boolean;
  currentPlaylist: Playlist | null;
}

export const TimerController: React.FC<TimerControllerProps> = ({
  totalTimeSec,
  isCountDown,
  currentPlaylist,
}) => {
  // タイマー再生中フラグ
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  // タイマー残り時間
  const [currentRestTimeSec, setCurrentRestTimeSec] = useState<number>(0);

  // タイマートータルタイム（internal）：propsをそのまま使うと時間差になるため
  const [internalTotalTimeSec, setInternalTotalTimeSec] = useState<number>(0);

  // 音声読み込み完了フラグ
  const [isVoiceLoaded, setIsVoiceLoaded] = useState<boolean>(false);

  // 自動再生有無確認用
  const location = useLocation();

  // 初期化
  useEffect(() => {
    // 音声の初期化
    // 初期化完了コールバックを設定
    platformAdapter.setOnVoiceLoaded(() => {
      setIsVoiceLoaded(true);
    });
    // 既に初期化済であれば即時フラグセット
    if (platformAdapter.isVoiceLoaded) {
      setIsVoiceLoaded(true);
    }

    // 再生中ステートのコールバックを設定
    talkingTimerMgr.setUpdateIsPlaying((flag: boolean) => {
      setIsPlaying(flag);
    });

    // 残り時間のコールバックを設定
    talkingTimerMgr.setUpdateCurrentRestTimeSec((timeSec: number) => {
      setCurrentRestTimeSec(timeSec);
    });

    // 自動再生
    if (talkingTimerMgr.current()) {
      talkingTimerMgr.handleReset();
      // location.stateがnull(URL起動)か、自動再生 trueの場合
      if (location.state === null || location.state?.autoPlay) {
        talkingTimerMgr.play();
      }
    }
  }, [location.state]);

  // totalTimeSec変更時はcurrentRestTimeSecも合わせて更新
  useEffect(() => {
    setCurrentRestTimeSec(totalTimeSec);
    setInternalTotalTimeSec(totalTimeSec);
  }, [totalTimeSec]);

  // カウントアップ/ダウンに伴うスライダーのマークの変更
  const [marks, setMarks] = useState<{ value: number; label: string }[]>([]);
  useEffect(() => {
    if (isCountDown) {
      setMarks([
        {
          value: 0,
          label: format(new Date(0, 0, 0, 0, 0, totalTimeSec), "HH:mm:ss"),
        },
        {
          value: totalTimeSec,
          label: "00:00:00",
        },
      ]);
    } else {
      setMarks([
        {
          value: 0,
          label: "00:00:00",
        },
        {
          value: totalTimeSec,
          label: format(new Date(0, 0, 0, 0, 0, totalTimeSec), "HH:mm:ss"),
        },
      ]);
    }
  }, [isCountDown, totalTimeSec]);

  // スライダーハンドラ
  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    talkingTimerMgr.currentRestTimeSec =
      internalTotalTimeSec - (newValue as number);
    setCurrentRestTimeSec(talkingTimerMgr.currentRestTimeSec);
  };

  // 経過時間
  const erapsedTime = () => {
    return internalTotalTimeSec - currentRestTimeSec;
  };

  // カウントアップ/ダウンに伴う経過時間or残り時間
  const timeToShow = () => {
    if (isCountDown) {
      return currentRestTimeSec;
    } else {
      return erapsedTime();
    }
  };

  return (
    <Grid
      container
      spacing={0}
      position="fixed"
      sx={{
        paddingTop: "5px",
        zIndex: 999, // 特殊コンテンツ以外での最大 : https://mui.com/material-ui/customization/z-index/
        backgroundColor: "#FFFFFFFF",
      }}
    >
      <Grid item display="flex" justifyContent="center" xs={4}></Grid>
      {/* 残り時間/経過時間 */}
      <Grid item display="flex" justifyContent="center" xs={4}>
        <Typography variant="h4">
          {format(new Date(0, 0, 0, 0, 0, timeToShow()), "HH:mm:ss")}
        </Typography>
      </Grid>

      {/* プレイリスト順 */}
      {currentPlaylist !== null && (
        <Grid
          item
          display="flex"
          justifyContent="center"
          xs={4}
          sx={{
            paddingTop: "5px",
          }}
        >
          <Typography variant="h5">
            {playlistMgr.getCurrentTimerIdx() +
              1 +
              " / " +
              currentPlaylist?.talkingTimerIDs.length}
          </Typography>
        </Grid>
      )}
      {/* スライダー */}
      <Grid
        item
        display="flex"
        justifyContent="center"
        xs={12}
        sx={{
          marginTop: "-5px",
          paddingLeft: "22px",
          paddingRight: "40px",
          marginBottom: "-20px",
        }}
      >
        <Slider
          value={erapsedTime()}
          onChange={handleChangeSlider}
          min={0}
          max={internalTotalTimeSec}
          step={1}
          valueLabelDisplay="off"
          marks={marks}
        />
      </Grid>
      {/* 戻る */}
      <Grid item display="flex" justifyContent="right" xs={4}>
        <IconButton onClick={() => playlistMgr.backwardOrReset()}>
          <SkipPreviousIcon fontSize="large" />
        </IconButton>
      </Grid>
      {/* 再生・停止ボタン */}
      <Grid item display="flex" justifyContent="center" xs={4}>
        <IconButton
          color="primary"
          onClick={() => talkingTimerMgr.TogglePlayOrPause()}
          disabled={!isVoiceLoaded}
        >
          {isPlaying ? (
            <PauseCircleIcon fontSize="large" />
          ) : (
            <PlayCircleIcon fontSize="large" />
          )}
        </IconButton>
      </Grid>
      {/* 進む */}
      <Grid item display="flex" justifyContent="left" xs={4}>
        <IconButton onClick={() => playlistMgr.forwardEx()}>
          <SkipNextIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
