import { gapiLoginWeb, gapiSetTokens } from "./gapi";
import { AsyncOperationManager } from "./AsyncOperationManager";

declare var Android: any;

declare global {
  interface Window {
    native2Web: (message: String) => void;
    setTokens: (
      access_token: String,
      refresh_token: String,
      expires_in_sec: String
    ) => void;
    onGetVolume1: (volume: number) => void;
    onGetVolume2: (volume: number) => void;
    adsbygoogle: any;
    adBreak: (o: any) => void;
  }
}

let is_webview = false;

class PlatformAdapter {
  onOneSecInterval: () => void;
  onVoiceLoaded: () => void;
  onGetVolume1: (volume: number) => void;
  onGetVolume2: (volume: number) => void;
  isVoiceLoaded: boolean;
  voiceLoad: AsyncOperationManager<any>;
  oneSecIntervalIdPc: ReturnType<typeof setInterval> | number;
  private isTimerActive: boolean;

  public constructor() {
    this.onOneSecInterval = () => {};
    this.onVoiceLoaded = () => {};
    this.onGetVolume1 = () => {};
    this.onGetVolume2 = () => {};
    this.isVoiceLoaded = false;
    this.voiceLoad = new AsyncOperationManager();
    this.oneSecIntervalIdPc = -1;
    this.isTimerActive = false;

    if (window.speechSynthesis) {
      speechSynthesis.onvoiceschanged = () => {
        this.onVoiceLoaded();
        this.isVoiceLoaded = true;
        this.voiceLoad.done();
      };

      window.speechSynthesis.getVoices();
    }
  }

  public speech(text: string) {
    if (window.speechSynthesis) {
      let utterThis = new SpeechSynthesisUtterance();
      utterThis.text = text;
      let voices = window.speechSynthesis.getVoices();
      for (let i = 0; i < voices.length; i++) {
        if (voices[i].lang === "ja-JP") {
          utterThis.voice = voices[i];
          break;
        }
      }
      window.speechSynthesis.speak(utterThis);
    } else {
      if (typeof Android !== "undefined") {
        Android.web2NativeText("WEB2NATIVE_SPEECH", text);
      }
    }
  }

  public setOnVoiceLoaded(callback: () => void) {
    this.onVoiceLoaded = callback;
  }

  public setOneSecInterval(callback: () => void) {
    if (!this.isTimerActive) {
      if (!is_webview) {
        this.oneSecIntervalIdPc = setInterval(callback, 1000);
      } else {
        if (typeof Android !== "undefined") {
          this.onOneSecInterval = callback;
          Android.web2Native("WEB2NATIVE_START_TIMER");
        }
      }
      this.isTimerActive = true;
    }
  }

  // clearIntervalのクロスプラットフォーム版
  public clearOneSecInterval() {
    if (this.isTimerActive) {
      if (!is_webview) {
        clearInterval(this.oneSecIntervalIdPc);
      } else {
        if (typeof Android !== "undefined") {
          Android.web2Native("WEB2NATIVE_STOP_TIMER");
        }
      }
      console.log("Clear Timer");
      this.isTimerActive = false;
    }
  }

  // 手動ログイン
  public gapiLogin() {
    // Google Drive認証テスト
    if (typeof Android !== "undefined") {
      Android.web2Native("WEB2NATIVE_AUTH");
    }
    // PCローカルデバッグ TODO: gapi.jsにまとめる
    else {
      gapiLoginWeb();
    }
  }

  // ショートカットの作成
  public createShortcut(type: string, id: string, name: string) {
    if (typeof Android !== "undefined") {
      Android.web2NativeCreateShortcut(type, id, name);
    }
  }

  // 音量の取得をリクエスト
  public requestGetVolume(index: number) {
    if (typeof Android !== "undefined") {
      Android.web2Native("WEB2NATIVE_GET_VOLUME" + index);
      // この後非同期で window.onGetVolumeX()が呼ばれる
    }
  }

  // 音量の取得コールバック関数の設定
  public setOnGetVolume(callback: (volume: number) => void, index: number) {
    if (index === 1) this.onGetVolume1 = callback;
    else if (index === 2) this.onGetVolume2 = callback;
    else {
      console.log("invalid index.");
    }
  }

  // 音量の設定
  public setVolume(volume: number) {
    if (typeof Android !== "undefined") {
      Android.web2NativeSetVolume(volume);
    }
  }

  // シェア
  public share(
    title: string,
    text: string,
    base64String: string,
    name: string
  ) {
    if (typeof Android !== "undefined") {
      Android.web2NativeShare(title, text, base64String, name);
    } else {
      /*
      navigator.share({
        title: title,
        text: text,
        url: url,
      });
      */
    }
  }
}

export const platformAdapter = new PlatformAdapter();

// ネイティブからの汎用コールバック
window.native2Web = function (message: String) {
  console.log("native2web:" + message);
  switch (message) {
    case "setIsWebView":
      is_webview = true;
      break;
    case "timerCallback":
      platformAdapter.onOneSecInterval();
      break;
    case "IsSpeechInitialized":
      // TOTO: PC版とコード共有
      platformAdapter.isVoiceLoaded = true;
      platformAdapter.onVoiceLoaded();
      platformAdapter.voiceLoad.done();
      break;
    default:
      alert(message);
      break;
  }
};

// ネイティブからのアクセストークン送信
window.setTokens = async function (
  access_token,
  refresh_token,
  expires_in_sec
) {
  // ローカルに保存
  gapiSetTokens(access_token, refresh_token, expires_in_sec);
};

// ネイティブからの音量取得1
window.onGetVolume1 = function (volume) {
  platformAdapter.onGetVolume1(volume);
};

// ネイティブからの音量取得2
window.onGetVolume2 = function (volume) {
  platformAdapter.onGetVolume2(volume);
};
