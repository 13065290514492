import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

import ListMui from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import EditIcon from "@mui/icons-material/Edit";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid"; // Grid version 1
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import "./index.css";
import { Adsense } from "@ctrl/react-adsense";

import { talkingTimerMgr } from "./lib/TalkingTimerMgr";
import { TalkingTimer } from "./lib/TalkingTimer";
import { playlistMgr } from "./lib/PlaylistMgr";
import { Playlist } from "./lib/Playlist";

import MessageTextField from "./MessageTextField";
import { platformAdapter } from "./lib/PlatformAdapter";

import { gapiGetProfileName, gapiSaveFile } from "./lib/gapi";

import queryString from "query-string";

// コンポーネント定義
const List: React.FC = () => {
  const navigate = useNavigate();

  // 確認ダイアログ
  const confirm = useConfirm();

  // タイマー一覧
  const [talkingTimers, setTalkingTimers] = useState<TalkingTimer[]>([]);

  // プレイリスト一覧
  const [timerPlayLists, setTimerPlayLists] = useState<Playlist[]>([]);

  // 初期化完了フラグ
  const [isInited, setIsInited] = useState<boolean>(false);

  // 初期化
  useEffect(() => {
    // クエリ文字列がある時はMainに遷移
    // TODO: 本来はサーバ側で対応
    if (window.location.search !== "") {
      // クエリ文字列を解析
      const parsed = queryString.parse(window.location.search);

      // 解析結果から値を取得
      const type = parsed.type;
      const id = parsed.id;
      if (type !== undefined) {
        navigate("/main?type=" + type + "&id=" + id);
      }
    } else {
      setIsInited(true);

      // データ初期化
      // タイマー
      talkingTimerMgr.idbLoad.wait().then(() => {
        setTalkingTimers(talkingTimerMgr.timers);
      });

      // プレイリスト
      playlistMgr.idbLoad.wait().then(() => {
        setTimerPlayLists(playlistMgr.playlists);
      });
    }
    // eslint-disable-next-line
  }, []);

  // タイマー選択ハンドラ
  const handleListItemClick = (index: React.Key) => {
    // 現在のタイマーを設定
    talkingTimerMgr.setCurrent(index as number);

    // 現在のプレイリストをなしに設定
    playlistMgr.setCurrent(-1);
    talkingTimerMgr.pause();
  };

  // プレイリスト選択ハンドラ　TODO:lib化
  const handlePlayListItemClick = (index: React.Key) => {
    // 現在のプレイリストを設定
    playlistMgr.setCurrent(index as number);

    // 現在のプレイリストの1つ目のタイマーを現在のタイマーとして設定
    const timerID = timerPlayLists[index as number].talkingTimerIDs[0];
    const timerIdx = talkingTimers.findIndex((timer) => timer.id === timerID);

    if (timerIdx < 0) {
      console.log("no timer");
      return;
    }
    talkingTimerMgr.setCurrent(timerIdx);
    talkingTimerMgr.pause();
  };

  // タイマー削除ハンドラ
  const handleTimerDelete = async (index: React.Key) => {
    // 現在のインデックスのタイマー
    const currentIndexData = talkingTimerMgr.timers[index as number];

    confirm({
      title: 'タイマー"' + currentIndexData.name + '"を削除しますか?',
      description: "この操作は元に戻せません。",
      confirmationButtonProps: { color: "secondary" },
    })
      .then(async () => {
        // 現在のインデックスのタイマーを削除してステートに設定
        setTalkingTimers(await talkingTimerMgr.delete(index as number));

        // localStorage上の現在のタイマーインデックスをリセット
        talkingTimerMgr.setCurrent(0);
      })
      .catch(() => {
        /* ... */
      });
  };

  // タイマーコピーハンドラ
  const handleTimerCopy = async (index: React.Key) => {
    // 現在のインデックスのタイマーをコピーしてステートに設定
    setTalkingTimers(await talkingTimerMgr.copy(index as number));

    // localStorage上の現在のタイマーインデックスをリセット
    talkingTimerMgr.setCurrent(0);
  };

  // タイマーシェアハンドラ
  const handleTimerShare = async (index: React.Key) => {
    //const blob = new Blob(["Hello World"], { type: "text/plain" });

    const data = talkingTimerMgr.timers[index as number];

    const res = await gapiSaveFile(data.name + ".json", data, true);
    const resJson = await (res as unknown as Response)?.json();

    // リンク
    const shareableLink = `https://drive.google.com/file/d/${resJson.id}/view?usp=drive_link`;
    console.log(`Your file is now accessible at: ${shareableLink}`);

    const blob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });
    const reader = new FileReader();
    reader.onloadend = async () => {
      // Remove header to get just the Base64 part of the data URL
      const base64String =
        reader.result
          ?.toString()
          .replace("data:application/json;base64,", "") || "";
      // Call the native method with the Base64 string
      platformAdapter.share(
        "Talking Timer: タイマーの共有",
        (await gapiGetProfileName()) +
          'さんから、タイマー "' +
          talkingTimerMgr.timers[index as number].name +
          '" が共有されました。\n' +
          "Talking Timer アプリで開いてください: \n" +
          shareableLink,
        base64String,
        talkingTimerMgr.timers[index as number].name + ".json"
      );
    };
    reader.readAsDataURL(blob);
  };

  // プレイリスト削除ハンドラ
  const handlePlaylistDelete = async (index: React.Key) => {
    // 現在のプレイリスト
    const currentPlaylist = playlistMgr.playlists[index as number];

    confirm({
      title: 'プレイリスト"' + currentPlaylist.name + '"を削除しますか?',
      description: "この操作は元に戻せません。",
      confirmationButtonProps: { color: "secondary" },
    })
      .then(async () => {
        // 現在のインデックスのプレイリストを削除してステートに設定
        setTimerPlayLists(await playlistMgr.delete(index as number));

        // localStorage上の現在のプレイリストインデックスをリセット
        playlistMgr.setCurrent(0);
      })
      .catch(() => {
        /* ... */
      });
  };

  // プレイリストコピーハンドラ
  const handlePlaylistCopy = async (index: React.Key) => {
    // 現在のインデックスのプレイリストをコピーしてステートに設定
    setTimerPlayLists(await playlistMgr.copy(index as number));

    // localStorage上の現在のプレイリストインデックスをリセット
    playlistMgr.setCurrent(0);
  };

  // プレイリスト内のタイマー削除ハンドラ
  const handleTimerInPlaylistDelete = async (
    index: React.Key,
    index2: React.Key
  ) => {
    // 現在のインデックスのデータ
    const currentPlaylist = playlistMgr.playlists[index as number] as Playlist;
    const timerID = currentPlaylist.talkingTimerIDs[index2 as number];

    confirm({
      title:
        'タイマー"' +
        talkingTimers.find((timer) => timer.id === timerID)?.name +
        '"をプレイリスト"' +
        currentPlaylist.name +
        '"から削除しますか?',
      description: "この操作は元に戻せません。タイマーの実体は削除されません。",
      confirmationButtonProps: { color: "secondary" },
    })
      .then(async () => {
        // 削除してステートに反映
        setTimerPlayLists(
          await playlistMgr.deleteTimer(index as number, index2 as number)
        );
      })
      .catch(() => {
        /* ... */
      });
  };

  // プレイリストへのタイマー追加
  const [openAddTimerDialog, setOpenAddTimerDialog] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<React.Key>(0);
  const handlePlaylistAddTimer = async (
    index: React.Key,
    index2: React.Key
  ) => {
    // 現在のインデックスのデータ
    const timerID = talkingTimerMgr.timers[index2 as number].id;

    // 追加してステートに反映
    setTimerPlayLists(await playlistMgr.addTimer(index as number, timerID));

    // ダイアログを閉じる
    setOpenAddTimerDialog(false);
  };

  // テキスト変更インターフェイス
  interface HandleChangeText {
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: React.Key
    ): void;
  }

  // プレイリスト名の変更
  const handleChangePlaylistText: HandleChangeText = async (event, index) => {
    // リネームしてステートに反映
    setTimerPlayLists(
      await playlistMgr.rename(index as number, event.target.value)
    );
  };

  // タイマー名の変更
  const handleChangeTimerText: HandleChangeText = async (event, index) => {
    // リネームしてステートに反映
    let newTimer = talkingTimerMgr.timers[index as number];
    newTimer.name = event.target.value;
    setTalkingTimers(await talkingTimerMgr.update(newTimer));
  };

  // タイマー詳細メニュー
  const [timerMenuAnchorEl, setTimerMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleClickTimerMenu = (
    event: React.MouseEvent<HTMLElement>,
    index: React.Key,
    timer: TalkingTimer
  ) => {
    setTimerMenuAnchorEl(event.currentTarget);
    timerMenuIndexRef.current = index;
    timerMenuIdRef.current = timer.id;
    timerMenuNameRef.current = timer.name;
  };
  const handleCloseTimerMenu = () => {
    setTimerMenuAnchorEl(null);
  };

  const timerMenuIndexRef = useRef<React.Key>(0);
  const timerMenuIdRef = useRef<string>("");
  const timerMenuNameRef = useRef<string>("");

  // プレイリスト詳細メニュー
  const [playlistMenuAnchorEl, setPlaylistMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const handleClickPlaylistMenu = (
    event: React.MouseEvent<HTMLElement>,
    index: React.Key,
    playlist: Playlist
  ) => {
    setPlaylistMenuAnchorEl(event.currentTarget);
    playlistMenuIndexRef.current = index;
    playlistMenuIdRef.current = playlist.id;
    playlistMenuNameRef.current = playlist.name;
  };
  const handleClosePlaylistMenu = () => {
    setPlaylistMenuAnchorEl(null);
  };

  const playlistMenuIndexRef = useRef<React.Key>(0);
  const playlistMenuIdRef = useRef<string>("");
  const playlistMenuNameRef = useRef<string>("");

  return (
    <>
      {isInited && (
        <Box
          p={1}
          sx={{
            paddingTop: "60px",
          }}
        >
          <div className="center-ad">
            <Adsense
              client="ca-pub-5097506678290473a"
              slot="8353008403"
              style={{ width: 320, height: 50 }}
              format=""
            />
          </div>
          {/* タイマー 一覧 */}
          <Grid container spacing={2} alignItems="center">
            {/* タイトル */}
            <Grid item xs={10}>
              <Typography variant="h6" display="flex" justifyContent="left">
                Timers
              </Typography>
            </Grid>

            {/* タイマー追加*/}
            <Grid item xs={2}>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={async () => {
                  setTalkingTimers(
                    await talkingTimerMgr.add(new TalkingTimer())
                  );
                }}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <ListMui>
            {talkingTimers !== undefined &&
              talkingTimers.map((timer, index: React.Key) => (
                <div key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* タイマーを自動再生 */}
                    <IconButton
                      edge="start"
                      onClick={() => {
                        handleListItemClick(index);
                        navigate("/main", { state: { autoPlay: true } });
                      }}
                    >
                      <PlayCircleIcon fontSize="large" color="primary" />
                    </IconButton>

                    {/* タイマー名（編集可能） */}
                    <MessageTextField
                      label=""
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleChangeTimerText(event, index)}
                      value={timer.name}
                      showPreview={false}
                    />

                    {/* タイマー詳細メニュー */}
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        handleClickTimerMenu(event, index, timer);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={timerMenuAnchorEl}
                      open={Boolean(timerMenuAnchorEl)}
                      onClose={handleCloseTimerMenu}
                    >
                      {/* タイマーを編集 */}
                      <MenuItem
                        onClick={() => {
                          handleListItemClick(timerMenuIndexRef.current);
                          navigate("/main", { state: { autoPlay: false } });
                          handleCloseTimerMenu();
                        }}
                      >
                        <EditIcon /> Edit
                      </MenuItem>

                      {/* タイマーコピー */}
                      <MenuItem
                        onClick={() => {
                          handleTimerCopy(timerMenuIndexRef.current);
                          handleCloseTimerMenu();
                        }}
                      >
                        <ContentCopyIcon /> Copy
                      </MenuItem>

                      {/* タイマー削除 */}
                      <MenuItem
                        onClick={() => {
                          handleTimerDelete(timerMenuIndexRef.current);
                          handleCloseTimerMenu();
                        }}
                      >
                        <DeleteIcon /> Delete
                      </MenuItem>

                      {/* ショートカットの作成 */}
                      <MenuItem
                        onClick={() => {
                          platformAdapter.createShortcut(
                            "timer",
                            timerMenuIdRef.current,
                            timerMenuNameRef.current
                          );
                          handleCloseTimerMenu();
                        }}
                      >
                        <InstallMobileIcon /> Create Shortcut
                      </MenuItem>

                      {/* タイマーのシェア */}
                      <MenuItem
                        onClick={() => {
                          handleTimerShare(timerMenuIndexRef.current);
                          handleCloseTimerMenu();
                        }}
                      >
                        <ShareIcon /> Share
                      </MenuItem>
                    </Menu>
                  </Box>
                </div>
              ))}
          </ListMui>
          <Divider />
          {/* プレイリスト一覧 */}
          <div className="center-ad">
            <Adsense
              client="ca-pub-5097506678290473a"
              slot="8353008403"
              style={{ width: 320, height: 50 }}
              format=""
            />
          </div>
          <Grid container spacing={2} alignItems="center">
            {/* タイトル */}
            <Grid item xs={10}>
              <Typography variant="h6" display="flex" justifyContent="left">
                Playlists
              </Typography>
            </Grid>

            {/* プレイリスト追加*/}
            <Grid item xs={2}>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={async () => {
                  setTimerPlayLists(await playlistMgr.add(new Playlist()));
                }}
              >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <ListMui>
            {timerPlayLists !== undefined &&
              timerPlayLists.map((playlist, index: React.Key) => (
                <div key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* プレイリストを自動再生 */}
                    <IconButton
                      edge="start"
                      onClick={() => {
                        handlePlayListItemClick(index);
                        navigate("/main", { state: { autoPlay: true } });
                      }}
                    >
                      <PlayCircleIcon fontSize="large" color="primary" />
                    </IconButton>

                    {/* プレイリスト名（編集可能） */}
                    <MessageTextField
                      label=""
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => handleChangePlaylistText(event, index)}
                      value={playlist.name}
                      showPreview={false}
                    />

                    {/* プレイリスト詳細メニュー */}
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        handleClickPlaylistMenu(event, index, playlist);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={playlistMenuAnchorEl}
                      open={Boolean(playlistMenuAnchorEl)}
                      onClose={handleClosePlaylistMenu}
                    >
                      {/* プレイリストにタイマー追加 */}
                      <MenuItem
                        onClick={() => {
                          setActiveIndex(playlistMenuIndexRef.current);
                          setOpenAddTimerDialog(true);
                          handleClosePlaylistMenu();
                        }}
                      >
                        <PlaylistAddCircleIcon /> Add timer
                      </MenuItem>

                      {/* プレイリストコピー */}
                      <MenuItem
                        onClick={() => {
                          handlePlaylistCopy(playlistMenuIndexRef.current);
                          handleClosePlaylistMenu();
                        }}
                      >
                        <ContentCopyIcon /> Copy
                      </MenuItem>

                      {/* プレイリスト削除 */}
                      <MenuItem
                        onClick={() => {
                          handlePlaylistDelete(playlistMenuIndexRef.current);
                          handleClosePlaylistMenu();
                        }}
                      >
                        <DeleteIcon /> Delete
                      </MenuItem>

                      {/* ショートカットの作成 */}
                      <MenuItem
                        onClick={() => {
                          platformAdapter.createShortcut(
                            "playlist",
                            playlistMenuIdRef.current,
                            playlistMenuNameRef.current
                          );
                          handleClosePlaylistMenu();
                        }}
                      >
                        <InstallMobileIcon /> Create Shortcut
                      </MenuItem>
                    </Menu>
                  </Box>
                  {/* 追加用タイマー一覧ダイアログ */}
                  <Dialog
                    onClose={() => {
                      setOpenAddTimerDialog(false);
                    }}
                    open={openAddTimerDialog}
                  >
                    <DialogTitle>追加するタイマーを選択</DialogTitle>

                    <ListMui>
                      {talkingTimers !== undefined &&
                        talkingTimers.map((timer, index2: React.Key) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={index2}
                          >
                            {/* タイマー追加 */}
                            <ListItemButton
                              onClick={() =>
                                handlePlaylistAddTimer(activeIndex, index2)
                              }
                              divider={true}
                            >
                              <ListItemText primary={timer.name} />
                            </ListItemButton>
                          </Box>
                        ))}
                    </ListMui>
                  </Dialog>

                  {/* プレイリスト内のタイマー一覧 */}
                  <ListMui>
                    {playlist.talkingTimerIDs !== undefined &&
                      playlist.talkingTimerIDs.map(
                        (timerID, index2: React.Key) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingLeft: "40px",
                            }}
                            key={index2}
                          >
                            {/* プレイリスト内のタイマー */}
                            <ListItemButton
                              //onClick={() => handlePlayListItemClick(index)}
                              divider={true}
                            >
                              <ListItemText
                                primary={
                                  talkingTimers.find(
                                    (timer) => timer.id === timerID
                                  )?.name
                                }
                              />
                            </ListItemButton>

                            {/* プレイリスト内のタイマー削除 */}
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                handleTimerInPlaylistDelete(index, index2)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )
                      )}
                  </ListMui>
                </div>
              ))}
          </ListMui>
        </Box>
      )}
    </>
  );
};

export default List;
