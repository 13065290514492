// メイン画面

import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import { TimerController } from "./TimerController";
import Sequencer from "./Sequencer";

import { talkingTimerMgr } from "./lib/TalkingTimerMgr";
import { TalkingTimer } from "./lib/TalkingTimer";
import { playlistMgr } from "./lib/PlaylistMgr";
import { Playlist } from "./lib/Playlist";
import { platformAdapter } from "./lib/PlatformAdapter";

// コンポーネント定義
const Main: React.FC = () => {
  // プレイリスト名
  const [currentTimerPlayListName, setCurrentTimerPlayListName] =
    useState<string>("");

  // ボリューム
  // TODO:丸ごとSequencerに持っていく可能性も？
  const [currentTimerPlayListVol, setCurrentTimerPlayListVol] =
    useState<number>(-2);

  const [currentTimerVol, setCurrentTimerVol] = useState<number>(-2);

  const onGetVolume2 = (volume: number) => {
    // プレイリスト優先
    if (playlistMgr.current() !== null) {
      setCurrentTimerPlayListVol(volume);

      playlistMgr.setVolume(volume);
    } else {
      setCurrentTimerVol(volume);

      let newTimer = talkingTimerMgr.current();
      if (newTimer) {
        newTimer.volume = volume;
        talkingTimerMgr.update(newTimer);
      }
    }
  };

  // 現在のタイマーステート
  const [talkingTimer, setTalkingTimer] = useState<TalkingTimer | null>(null);

  // 現在のタイマーを読み込み
  const loadCurrentTimer = () => {
    if (talkingTimerMgr.current()) {
      setTalkingTimer(talkingTimerMgr.current());
      setCurrentTimerVol(talkingTimerMgr.current()!.volume);

      // 現在の残り時間をトータル時間とする
      talkingTimerMgr.currentRestTimeSec =
        talkingTimerMgr.current()!.totalTimeSec;
    }
  };

  // 現在のプレイリストを読み込み
  const [currentPlaylist, setCurrentPlaylist] = useState<Playlist | null>(null);
  const loadCurrentPlaylist = () => {
    if (playlistMgr.current()) {
      setCurrentTimerPlayListName(playlistMgr.current()!.name);
      setCurrentTimerPlayListVol(playlistMgr.current()!.volume);
      setCurrentPlaylist(playlistMgr.current());
    } else {
      setCurrentPlaylist(null);
    }
  };

  // 初期化
  useEffect(() => {
    // タイマー
    talkingTimerMgr.idbLoad.wait().then(() => {
      loadCurrentTimer();

      // プレイリスト
      playlistMgr.idbLoad.wait().then(() => {
        loadCurrentPlaylist();
      });
    });

    // タイマー切り替え時コールバック設定
    playlistMgr.setOnTimerChange(loadCurrentTimer);

    // 音量取得コールバック設定
    platformAdapter.setOnGetVolume(onGetVolume2, 2);
  }, []);

  if (talkingTimer === null) {
    return <></>;
  } else {
    return (
      <>
        <Box
          p={1}
          sx={{
            paddingTop: "55px",
          }}
        >
          {/* タイマーコントローラ */}
          <TimerController
            totalTimeSec={talkingTimer.totalTimeSec}
            isCountDown={talkingTimer.isCountDown}
            currentPlaylist={currentPlaylist}
          ></TimerController>
        </Box>
        <Box
          p={1}
          sx={{
            paddingTop: "140px",
          }}
        >
          {/* シーケンサー */}
          <Sequencer
            currentTimerPlayListName={currentTimerPlayListName}
            currentTimerPlayListVol={currentTimerPlayListVol}
            currentTimerVol={currentTimerVol}
            talkingTimer={talkingTimer}
            setTalkingTimer={setTalkingTimer}
          />
        </Box>
      </>
    );
  }
};

export default Main;
