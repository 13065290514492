import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import TimeTextField from "./TimeTextField";

// コンポーネント定義
export interface PasteDialogProps {
  open: boolean;
  timeSec: number;
  onClose: () => void;
  onChange: (value: number) => void;
  countDownOrUp?: string; // カウンドダウントグル
  totalTimeSec?: number; // トータルタイム
}

const PasteDialog: React.FC<PasteDialogProps> = ({
  open,
  timeSec,
  onClose,
  onChange,
  countDownOrUp = "countDown",
  totalTimeSec = 0,
}) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Paste to:</DialogTitle>

      <TimeTextField
        label="貼り付け先時刻"
        timeSec={timeSec}
        onChange={(value) => {
          onChange(value);
        }}
        countDownOrUp={countDownOrUp}
        totalTimeSec={totalTimeSec}
        enterByButton={true}
      />
    </Dialog>
  );
};

export default PasteDialog;
