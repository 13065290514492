import { db } from "../idb";

import {
  gapiGetJsonObj,
  gapiSaveFile,
  gapiGetModifiedTime,
  gapiAutoLogin,
} from "./gapi";

export type CloudSyncState = "OFF" | "DOWNLOAD" | "UPLOAD" | "DONE" | "UNKNOWN";

class DbAdapter {
  // クラウド同期ステート
  private cloudSyncState: CloudSyncState;
  private onChangeCloudSyncState = (cloudSyncState: CloudSyncState) => {};
  isDriveDownloaded: boolean;
  isDriveDownloadConfirmed: boolean;

  public constructor() {
    this.cloudSyncState = "UNKNOWN";
    this.isDriveDownloaded = false;
    this.isDriveDownloadConfirmed = false;
  }

  // クラウド同期ステートの変更
  private setCloudSyncState(cloudSyncState: CloudSyncState) {
    this.cloudSyncState = cloudSyncState;

    this.onChangeCloudSyncState(cloudSyncState);
  }

  // クラウド同期ステート変更コールバック設定
  public setOnChangeCloudSyncState(
    callback: (cloudSyncState: CloudSyncState) => void
  ) {
    this.onChangeCloudSyncState = callback;
  }

  // Driveに全部保存
  private async saveAllToDrive(tableName: string, allData: any) {
    this.setCloudSyncState("UPLOAD");
    let res = await gapiSaveFile(tableName, allData);
    if (res) {
      this.setCloudSyncState("DONE");
    } else {
      this.setCloudSyncState("OFF");
    }
  }

  // データの追加
  public async add(tableName: string, data: any, allData: any) {
    // idbに追加
    await db.table(tableName).add(data);

    // Driveに全部保存
    this.saveAllToDrive(tableName, allData);
  }

  // データの更新
  public async update(tableName: string, data: any, allData: any) {
    // idbを更新
    await db.table(tableName).update(data.id, data);

    // Driveに全部保存
    this.saveAllToDrive(tableName, allData);
  }

  // データの削除
  public async delete(tableName: string, id: any, allData: any) {
    // iDBに反映
    await db.table(tableName).delete(id);

    // Driveに全部保存
    this.saveAllToDrive(tableName, allData);
  }

  // 自動ログイン & Driveからのロードと同期
  public async loadAndSyncToDriveWithLogin(tableName: string, data: any) {
    // 自動ログイン
    if (await gapiAutoLogin()) {
      // Driveからのロードと同期
      await this.loadAndSyncToDrive(tableName, data);
    }
  }

  // ローカルデータの全ロード
  public async loadLocal(tableName: string) {
    // iDBより全データロード
    let data = await db.table(tableName).toArray();

    return data;
  }

  // Driveからのロードと同期
  private async loadAndSyncToDrive(tableName: string, data: any) {
    // ローカル・Driveのタイムスタンプを取得
    let modifiedTimeLocal = localStorage.getItem("modifiedTime_" + tableName);

    let modifiedTimeDrive = await gapiGetModifiedTime(tableName);

    // ローカルにタイムスタンプがある場合
    if (modifiedTimeLocal !== null) {
      let modifiedTimeLocalMsec = new Date(modifiedTimeLocal).getTime();

      // Driveにもタイムスタンプがある場合
      if (modifiedTimeDrive !== null) {
        let modifiedTimeDriveMsec = new Date(modifiedTimeDrive).getTime();

        // Driveの方が新しければローカルに取り込んで再起動
        if (modifiedTimeLocalMsec < modifiedTimeDriveMsec) {
          await this.loadDriveToLocal(
            tableName,
            modifiedTimeDrive,
            modifiedTimeLocal,
            true
          );
        }
        // ローカルの方が新しければ、サーバを更新 ※未同期のローカル更新がある場合の特殊ケース
        else if (modifiedTimeLocalMsec > modifiedTimeDriveMsec) {
          await this.saveAllToDrive(tableName, data);
        }
        // Driveとローカルが同じ時間であれば何もしない→同期はOK
        else {
          this.setCloudSyncState("DONE");
        }
      }
      // Driveにタイムスタンプがない場合はサーバを更新
      else {
        await this.saveAllToDrive(tableName, data);
      }
    }
    // ローカルにタイムスタンプがない場合
    else {
      // Driveにはタイムスタンプがある場合、ローカルに取り込んで再起動
      if (modifiedTimeDrive !== null) {
        await this.loadDriveToLocal(
          tableName,
          modifiedTimeDrive,
          modifiedTimeLocal!,
          false
        );
      }
      // どちらにもタイムスタンプがない場合は何もしない→データが空の状態で同期されている
      else {
        this.setCloudSyncState("DONE");
      }
    }
  }

  // Driveからローカルへのロード
  private async loadDriveToLocal(
    tableName: string,
    modifiedTimeDrive: string,
    modifiedTimeLocal: string,
    needsConfirm: boolean
  ) {
    if (
      !needsConfirm || // 確認不要指示
      this.isDriveDownloadConfirmed || // 既に別テーブルで確認済
      window.confirm(
        "Google Driveに新しいデータがあります。取り込んでアプリを再起動しますか?\nDrive:" +
          modifiedTimeDrive +
          "\nLocal:" +
          modifiedTimeLocal
      )
    ) {
      this.isDriveDownloadConfirmed = true;
      this.setCloudSyncState("DOWNLOAD");
      let data = await gapiGetJsonObj(tableName);

      if (data === null) {
        this.setCloudSyncState("OFF");
      } else {
        await db.table(tableName).clear();
        await db.table(tableName).bulkPut(data);

        localStorage.setItem(
          "modifiedTime_" + tableName,
          await gapiGetModifiedTime(tableName)
        );
        this.isDriveDownloaded = true;
      }
    } else {
      this.setCloudSyncState("OFF");
    }
  }
}

export const dbAdapter = new DbAdapter();
