// gapi.js

import AsyncLock from "async-lock";
import { addSeconds } from "date-fns";

let _access_token = "";
let _refresh_token = "";
let _expires = new Date(0);

let onLoginStatusChange = (status) => {};

// ログイン状態変換コールバック
export const gapiSetOnLoginStatusChange = (func) => {
  onLoginStatusChange = func;
};

const APP_FOLDER_NAME = ".TalkingTimerBackup";

export const GOOGLE_DRIVE_ICON_URL =
  "https://fonts.gstatic.com/s/i/productlogos/drive_2020q4/v8/web-64dp/logo_drive_2020q4_color_2x_web_64dp.png";

// 手動ログイン (web)
export const gapiLoginWeb = async () => {
  const REDIRECT_URI = "http://localhost:3000";
  const SCOPES =
    "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile";

  const authorizationUrl =
    "https://accounts.google.com/o/oauth2/v2/auth" +
    "?client_id=" +
    process.env.REACT_APP_CLIENT_ID +
    "&redirect_uri=" +
    REDIRECT_URI +
    "&response_type=code" +
    "&scope=" +
    SCOPES +
    "&access_type=offline";

  window.newWindow = window.open(authorizationUrl, "_blank");

  setTimeout(() => {
    const query = new URLSearchParams(window.newWindow.location.search);

    const code = decodeURIComponent(query.get("code"));

    window.newWindow.close();

    if (code === "null") {
      alert("認証エラーです。");
    } else {
      // 認証コードからアクセストークンを取得
      const redirectUri = "http://localhost:3000";

      const tokenEndpoint = "https://oauth2.googleapis.com/token";

      const requestBody = new URLSearchParams();
      requestBody.append("grant_type", "authorization_code");
      requestBody.append("code", code);
      requestBody.append("redirect_uri", redirectUri);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_CLIENT_ID +
                ":" +
                process.env.REACT_APP_CLIENT_SECRET
            ),
        },
        body: requestBody,
      };

      fetch(tokenEndpoint, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          gapiSetTokens(data.access_token, data.refresh_token, data.expires_in);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, 10000); // 10 秒待機します。
};

// 自動ログイン
// 成功はtrue, 失敗はfalseを返す
export const gapiAutoLogin = async () => {
  // idb内のリフレッシュトークンを取得
  _refresh_token = localStorage.getItem("refresh_token");

  // アクセストークンの更新
  return await refreshAccessToken();
};

// ログオフ
export const gapiLogoff = () => {
  _refresh_token = "";
  _access_token = "";
  localStorage.setItem("refresh_token", "");
  onLoginStatusChange(false, GOOGLE_DRIVE_ICON_URL);
};

// アクセストークンを設定
export const gapiSetTokens = async (
  access_token,
  refresh_token,
  expires_in_sec
) => {
  try {
    _access_token = access_token;
    onLoginStatusChange(true, await gapiGetProfileIcon());
    _refresh_token = refresh_token;
    // 有効期限を実際の60秒前に設定
    _expires = addSeconds(new Date(), expires_in_sec - 60);

    // console.log("expires_in_sec:" + expires_in_sec);
    // console.log("expires:" + _expires);

    // リフレッシュトークン保存
    localStorage.setItem("refresh_token", _refresh_token);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// アクセストークンの更新
// 成功はtrue, 失敗はfalseを返す
const refreshAccessToken = async () => {
  try {
    // リフレッシュトークンが無効な場合は何もしない
    // TOTO: リフレッシュトークン自体の有効期限確認
    if (
      _refresh_token === null ||
      _refresh_token === "" ||
      _refresh_token === "undefined"
    ) {
      return false;
    }

    if (new Date().getTime() > _expires.getTime()) {
      // アクセストークンの更新
      const API_KEY = "AIzaSyAvhXa84DkNz3fAoWWxha1gYpF-1PLq6tw";
      const response = await fetch(
        "https://oauth2.googleapis.com/token?key=" + API_KEY,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            refresh_token: _refresh_token,
            grant_type: "refresh_token",
          }),
        }
      );
      const data = await response.json();
      gapiSetTokens(data.access_token, _refresh_token, data.expires_in);
    }
    return true;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// ファイル or フォルダIDを取得（なければnull, 重複している場合は警告を出して1番目を返す)
const getFIleOrFolderID = async (name, parent_id) => {
  try {
    const url = `https://www.googleapis.com/drive/v3/files?q=name='${encodeURIComponent(
      name
    )}'and parents='${parent_id}' and trashed=false&fields=files(id,modifiedTime)&access_token=${_access_token}`;

    const response = await fetch(url);
    const data = await response.json();

    if (data.files === undefined) {
      // TODO: エラーハンドリングの条件精査
      console.log(data.error);

      return null;
    } else if (data.files.length === 0) {
      return null;
    } else if (data.files.length === 1) {
      return data.files[0].id;
    } else {
      let oldest_file = data.files[0];
      for (let i = 1; i < data.files.length; i++) {
        if (oldest_file.modifiedTime > data.files[i].modifiedTime) {
          oldest_file = data.files[i];
        }
        return oldest_file.id;
      }
      console.log(
        name +
          " are duplicated. The oldest one is used for now, but please check the Drive."
      );
      return data.files[0].id;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// 既存のファイルを更新。responseをそのまま返す
const updateFile = async (id, name, file) => {
  try {
    let url =
      "https://www.googleapis.com/upload/drive/v3/files/" +
      id +
      "?uploadType=multipart";
    const metadata = new Blob(
      [
        JSON.stringify({
          name: name,
        }),
      ],
      {
        type: "application/json; charset=UTF-8",
      }
    );

    const formData = new FormData();
    formData.append("Metadata", metadata);
    formData.append("Media", file);

    let res = await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${_access_token}`,
      },
      body: formData,
    });

    if (res.ok) {
    } else {
    }

    return res;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// 新規にファイルを保存。レスポンスをそのまま返す。
const uploadFile = async (folder_id, name, file, shared) => {
  try {
    let url =
      "https://www.googleapis.com/upload/drive/v3/files/?uploadType=multipart";
    const metadata = new Blob(
      [
        JSON.stringify({
          name: name,
          parents: [folder_id],
        }),
      ],
      {
        type: "application/json; charset=UTF-8",
      }
    );

    const formData = new FormData();
    formData.append("Metadata", metadata);
    formData.append("Media", file);

    let res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${_access_token}`,
      },
      body: formData,
    });

    const data = await res.json();

    if (res.ok) {
      if (shared) {
        // パーミッション設定
        let permissionRef = await fetch(
          `https://www.googleapis.com/drive/v3/files/${data.id}/permissions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${_access_token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              role: "reader",
              type: "anyone",
            }),
          }
        );

        if (permissionRef.ok) {
        } else {
        }
      }
    } else {
    }

    return res;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// フォルダIDを返す。なければ生成。エラー時はnullを返す。
const getFolderID = async (folder_name, parent_folder_id) => {
  // フォルダIDを取得
  let folder_id = await getFIleOrFolderID(folder_name, parent_folder_id);
  // なければ生成
  if (folder_id == null) {
    try {
      let createFolderOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${_access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mimeType: "application/vnd.google-apps.folder",
          name: folder_name,
          parents: [parent_folder_id],
        }),
      };

      const response = await fetch(
        "https://www.googleapis.com/drive/v3/files",
        createFolderOptions
      ).catch((error) => {
        alert("fetch:" + error); // エラー表示
      });
      const json = await response.json().catch((error) => {
        alert("json:" + error); // エラー表示
      });

      if (json.id === undefined) {
        return null;
      } else {
        return json.id;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
  // あればそのまま返却
  else {
    return folder_id;
  }
};

// APPのフォルダ階層を介したフォルダIDを返す。なければ生成。エラー時はnullを返す。
const gapiGetSubFolderId = async () => {
  let folderID = "root";
  folderID = await getFolderID(APP_FOLDER_NAME, folderID);
  return folderID;
};

const lock = new AsyncLock();

// データをjsonファイルとして保存する。成功した場合はresponse, 失敗した場合はnullを返す。
export const gapiSaveFile = async (name, data, shared) => {
  try {
    // アクセスがアクティブでない場合はnullを返す
    if (!isActive()) {
      return null;
    }

    // アクセストークン更新
    if (await refreshAccessToken()) {
      // フォルダIDを取得
      let folder_id = await gapiGetSubFolderId();

      if (folder_id != null) {
        let response;
        // ファイルをアップロード: async-lock適用
        await lock.acquire("gapi_save_file_lock_key", async () => {
          // ファイルがあるかどうか確認
          const file_id = await getFIleOrFolderID(name, folder_id);

          // あれば更新、なければ生成
          const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
          });

          if (file_id != null) {
            response = await updateFile(file_id, name, blob);
          } else {
            response = await uploadFile(folder_id, name, blob, shared);
          }
          //    });
          if (response.ok) {
            // 最新のmodifiedTimeをローカル保存
            let modifiedTime = await gapiGetModifiedTime(name); // TODO ここだけのエラーハンドリング
            localStorage.setItem("modifiedTime_" + name, modifiedTime);
          } else {
            // TODO: エラーメッセージなど
          }
        });
        return response;
      } else {
        // TODO: エラーメッセージなど
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// フォルダ内のファイル名をリストアップ。成功した場合はファイル名の配列を、そうでない場合はnullを返す
export const gapiListFiles = async () => {
  try {
    // アクセスがアクティブでない場合はnullを返す
    if (!isActive()) {
      return null;
    }

    // アクセストークン更新
    if (await refreshAccessToken()) {
      // フォルダIDを取得
      let folder_id = await gapiGetSubFolderId();

      const res = await fetch(
        `https://www.googleapis.com/drive/v3/files?q=parents='${folder_id}' and trashed=false&fields=files(name,version)&access_token=${_access_token}`
      );

      const res_json = await res.json();
      const fileArray = res_json.files;
      return fileArray;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// jsonファイルの中身を呼んでオブジェクトとして返す.なければnull
export const gapiGetJsonObj = async (name) => {
  try {
    // アクセスがアクティブでない場合はnullを返す
    if (!isActive()) {
      return null;
    }

    // アクセストークン更新
    if (await refreshAccessToken()) {
      // フォルダIDを取得
      let folder_id = await gapiGetSubFolderId();

      const file_id = await getFIleOrFolderID(name, folder_id);

      if (file_id != null) {
        const DRIVE_API_URL =
          "https://www.googleapis.com/drive/v3/files/" + file_id + "?alt=media";

        const REQUEST = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${_access_token}`,
            "Content-Type": "application/json",
          },
        };

        const RESPONSE = await fetch(DRIVE_API_URL, REQUEST);

        const BODY = await RESPONSE.text();
        return JSON.parse(BODY);
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// ファイルのmodifiedTimeを返す.なければnull
export const gapiGetModifiedTime = async (name) => {
  try {
    // アクセスがアクティブでない場合はnullを返す
    if (!isActive()) {
      return null;
    }

    // アクセストークン更新
    if (await refreshAccessToken()) {
      // フォルダIDを取得
      let folder_id = await gapiGetSubFolderId();

      const file_id = await getFIleOrFolderID(name, folder_id);

      if (file_id != null) {
        const DRIVE_API_URL =
          "https://www.googleapis.com/drive/v3/files/" +
          file_id +
          "?fields=modifiedTime";

        const REQUEST = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${_access_token}`,
            "Content-Type": "application/json",
          },
        };

        const RESPONSE = await fetch(DRIVE_API_URL, REQUEST);
        const JSON = await RESPONSE.json();

        return JSON.modifiedTime;
      } else {
        return null;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

// ファイルの削除。
// 成功：0
// アクセスに失敗 : 1
// アクセス出来たがファイルが存在しない : 2
export const gapiDeleteFile = async (name) => {
  try {
    // アクセスがアクティブでない場合はnullを返す
    if (!isActive()) {
      return 1;
    }

    // アクセストークン更新
    if (await refreshAccessToken()) {
      // フォルダIDを取得
      let folder_id = await gapiGetSubFolderId();

      const file_id = await getFIleOrFolderID(name, folder_id);

      if (file_id != null) {
        const DRIVE_API_URL =
          "https://www.googleapis.com/drive/v3/files/" + file_id;

        const REQUEST = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${_access_token}`,
          },
        };

        const RESPONSE = await fetch(DRIVE_API_URL, REQUEST);

        console.log(RESPONSE);

        if (RESPONSE.ok) {
          console.log("Drive削除");
          return 0;
        } else {
          console.log("Drive削除 エラー");
          return 1;
        }
      } else {
        console.log("Drive削除 ファイルが存在しない");
        return 2;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const isActive = () => {
  try {
    let ret =
      _access_token !== "" && // アクセストークンが存在
      //  new Date().getTime() <= _expires.getTime() && // アクセストークンが有効期限内:自動更新想定なので見ない
      navigator.onLine;

    return ret;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const gapiGetProfileIcon = async () => {
  const response = await fetch(
    "https://people.googleapis.com/v1/people/me?personFields=photos",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_access_token}`,
      },
    }
  );

  if (!response.ok) {
    console.log("gapiGetProfileIcon fails:" + response.statusText);
    return "";
  }

  const data = await response.json();
  const profileImageURL = data.photos[0].url;

  return profileImageURL;
};

export const gapiGetProfileName = async () => {
  const response = await fetch(
    "https://people.googleapis.com/v1/people/me?personFields=names",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${_access_token}`,
      },
    }
  );

  if (!response.ok) {
    console.log("gapiGetProfileName fails:" + response.statusText);
    return "";
  }

  const data = await response.json();
  if (data.names) {
    const name = data.names[0].displayName;
    return name;
  } else {
    return "";
  }
};

// Googleの連絡先をリストアップ
export async function listContacts() {
  let connections = [];
  let pageToken;

  // 一回のfetchで最大100件までしか取れないので、nextPageTokenがなくなるまで反復
  do {
    const url = `https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&pageToken=${
      pageToken || ""
    }`;
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${_access_token}`);

    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      console.log("listContacts fails:" + response.statusText);
      return "";
    }
    const data = await response.json();
    pageToken = data.nextPageToken;
    connections = [...connections, ...data.connections];
  } while (pageToken);

  // emailを持たない連絡先は除去
  connections = connections.filter((connection) =>
    connection.hasOwnProperty("emailAddresses")
  );
  return connections;
}
