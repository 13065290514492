import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import "./index.css";
import Main from "./Main";
import List from "./List";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <App />
      <Routes>
        <Route path="/main" element={<Main />} />
        <Route path="/" element={<List />} />
      </Routes>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement!);
root.render(
  //<React.StrictMode>
  <ConfirmProvider>
    <RouterConfig />
  </ConfirmProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
