import { v4 as uuidv4 } from "uuid";

// カスタムメッセージ型
export class CustomMessage {
  timeSec: number;
  message: string;
  id: string;

  public constructor(lastTimeSec: number) {
    this.message = "Custom Message";
    this.timeSec = lastTimeSec;
    this.id = uuidv4();
  }
}

export class TalkingTimer {
  name: string;
  totalTimeSec: number;
  periodicTimeSec: number;
  periodicMessage: string;
  startMessage: string;
  endMessage: string;
  customMessages: CustomMessage[];
  forceUpdate: string;
  isCountDown: boolean;
  id: string;
  isDriveSynced: boolean;
  volume: number;

  public constructor() {
    this.name = "New Timer";
    this.totalTimeSec = 0;
    this.periodicTimeSec = 0;
    this.periodicMessage = "";
    this.startMessage = "";
    this.endMessage = "";
    this.customMessages = [];
    this.forceUpdate = "";
    this.isCountDown = true;
    this.id = "";
    this.isDriveSynced = false;
    this.volume = -1;
  }
}
