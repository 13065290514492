// TimeFiledをラップし、その入出力を秒に変換するコンポーネント

import React, { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimeField } from "@mui/x-date-pickers";
import { getHours, getMinutes, getSeconds } from "date-fns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// プロパティ
interface TimeTextFieldProps {
  label: string; // ラベル
  timeSec: number; // 時間[秒]
  onChange: (timeSec: number) => void; // valueの変更を親に伝えるハンドラ
  countDownOrUp?: string; // カウンドダウントグル
  totalTimeSec?: number; // トータルタイム
  enterByButton?: boolean;
  forceUpdate?: string; // 強制的にsetTime()
}

// コンポーネント定義
const TimeTextField: React.FC<TimeTextFieldProps> = ({
  label,
  timeSec,
  onChange,
  countDownOrUp = "countDown",
  totalTimeSec = 0,
  enterByButton = false,
  forceUpdate = "",
}) => {
  // Date
  const [time, setTime] = useState<Date | null>(null);

  // 入力：timeSec(秒)からDate変換
  useEffect(() => {
    let newTimeSec = timeSec;
    if (countDownOrUp === "countUp") {
      newTimeSec = totalTimeSec - newTimeSec;
    }
    setTime(new Date(0, 0, 0, 0, 0, newTimeSec));
  }, [timeSec, countDownOrUp, totalTimeSec, forceUpdate]);

  // 出力：DateからtimeSec(秒)変換
  const handleDateToSec = (newDate: Date | null) => {
    if (newDate !== null) {
      let newTimeSec =
        getHours(newDate) * 3600 +
        getMinutes(newDate) * 60 +
        getSeconds(newDate);
      if (countDownOrUp === "countUp") {
        newTimeSec = totalTimeSec - newTimeSec;
      }
      if (newTimeSec !== timeSec || enterByButton) {
        onChange(newTimeSec);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimeField
        label={label}
        variant="outlined"
        value={time}
        onChange={(value) => {
          setTime(value);
        }}
        onBlur={() => {
          if (!enterByButton) {
            handleDateToSec(time);
          }
        }}
        format="HH:mm:ss"
        InputProps={{
          endAdornment: enterByButton && (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                color="primary"
                onClick={() => {
                  handleDateToSec(time);
                }}
              >
                <CheckCircleIcon />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: (
            <IconButton edge="start">
              <TimerOutlinedIcon />
            </IconButton>
          ),
          style: { fontSize: 20, height: 45 },
        }}
      />
    </LocalizationProvider>
  );
};

export default TimeTextField;
