export class AsyncOperationManager<T> {
  private resolveOperation: ((value: T | PromiseLike<T>) => void) | null = null;
  private rejectOperation: ((reason?: any) => void) | null = null;
  private sharedPromise: Promise<T> | null = null;

  public done(value?: T): void {
    if (!this.sharedPromise) {
      this.sharedPromise = new Promise<T>((resolve, reject) => {
        this.resolveOperation = resolve;
        this.rejectOperation = reject;
      });
    }

    // sharedPromiseを解決する
    this.resolveOperation!(value as T);
  }

  public fail(reason?: any): void {
    if (!this.sharedPromise) {
      this.sharedPromise = new Promise<T>((resolve, reject) => {
        this.resolveOperation = resolve;
        this.rejectOperation = reject;
      });
    }

    // sharedPromiseを拒否する
    this.rejectOperation!(reason);
  }

  public async wait(): Promise<T> {
    if (!this.sharedPromise) {
      this.sharedPromise = new Promise<T>((resolve, reject) => {
        this.resolveOperation = resolve;
        this.rejectOperation = reject;
      });
    }

    // Operationの完了を待つ
    return this.sharedPromise;
  }
}

// 使用例
/*
const manager = new AsyncOperationManager<string>();

async function runAsyncOperations() {
  try {
    const result = await manager.wait();
    console.log("Operation has completed:", result);
  } catch (error) {
    console.error("Operation failed:", error);
  }
}

// 実際の操作を行う
const actualOperation = (): string => {
  // 実際の操作のロジック...
  console.log("Performing actual operation...");
  // 何らかの結果を返す
  return "Operation completed with result";
};

runAsyncOperations();

setTimeout(() => {
  // doneを呼び出してOperationが完了したことを通知する
  const result = actualOperation();
  manager.done(result);
}, 1500); // 1.5秒後にOperationを開始する
*/
