import React, { useState } from "react";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import TimeTextField from "./TimeTextField";

// コンポーネント定義
export interface InsertDialogProps {
  open: boolean;
  timeSec: number;
  onClose: () => void;
  onChange: (value: number) => void;
  countDownOrUp?: string; // カウンドダウントグル
  totalTimeSec?: number; // トータルタイム
}

const InsertDialog: React.FC<InsertDialogProps> = ({
  open,
  timeSec,
  onClose,
  onChange,
  countDownOrUp = "countDown",
  totalTimeSec = 0,
}) => {
  // ポジティブ/ネガティブ トグル処理
  const [isPositive, setisPositive] = useState<boolean>(true);

  const handleToggle = async (
    event: React.MouseEvent<HTMLElement>,
    newIsPositive: boolean
  ) => {
    if (newIsPositive !== null) {
      setisPositive(newIsPositive);
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Insert time:</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={isPositive}
              exclusive
              onChange={handleToggle}
              fullWidth
            >
              <ToggleButton value={true} fullWidth>
                +
              </ToggleButton>
              <ToggleButton value={false} fullWidth>
                -
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid xs={12}>
            <TimeTextField
              label="挿入時間"
              timeSec={timeSec}
              onChange={(value) => {
                onChange(isPositive ? value : -value);
                onClose();
              }}
              countDownOrUp={countDownOrUp}
              totalTimeSec={totalTimeSec}
              enterByButton={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InsertDialog;
