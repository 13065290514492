import { v4 as uuidv4 } from "uuid";

export class Playlist {
  name: string;
  talkingTimerIDs: string[];
  id: string;
  volume: number;

  public constructor() {
    this.name = "New Playlist";
    this.talkingTimerIDs = [];
    this.id = uuidv4();
    this.volume = -1;
  }
}
